// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-fi-blogi-tsx": () => import("./../../../src/pages/fi/blogi.tsx" /* webpackChunkName: "component---src-pages-fi-blogi-tsx" */),
  "component---src-pages-ideasta-tuote-js": () => import("./../../../src/pages/ideasta-tuote.js" /* webpackChunkName: "component---src-pages-ideasta-tuote-js" */),
  "component---src-templates-black-tsx": () => import("./../../../src/templates/black.tsx" /* webpackChunkName: "component---src-templates-black-tsx" */),
  "component---src-templates-blogi-tsx": () => import("./../../../src/templates/blogi.tsx" /* webpackChunkName: "component---src-templates-blogi-tsx" */)
}

